@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat'), url(../../fonts/mamedium.ttf);
}

@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat'), url(../../fonts/mabold.ttf);
}

@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 900;
    src: local('Montserrat'), url(../../fonts/mablack.ttf);
}


* { margin: 0; padding: 0; }

:root {
    --url-color: #DE0020;
    --text-color: #fff;
    --bg-color-main: #000;
    --bg-color-secondary: #54B6F9;

    --heading-text-size: 80px;
    --subheading-text-size: 30px;
    --section-text-size: 20px;
    --main-text-size: 16px;

    --main-padding: 30px;
}

html, body {
    background-color: #000;
    font-family: 'Montserrat Alternates', SansSerif, serif;
    font-weight: 400;
    color: var(--text-color);
    line-height: 1;
    overflow-x: hidden;
}

.wrapper {
    width: 100%;
    overflow-x: hidden;
}

h1 {
    font-weight: 900;
    font-size: var(--heading-text-size);
}

h2 {
    font-weight: 700;
    font-size: var(--subheading-text-size);
}

h3 {
    font-weight: 700;
    font-size: var(--section-text-size);
}

p {
    font-size: var(--main-text-size);
}

a {
    color: var(--url-color);
}

hr {
    border: #FFFFFF 1px solid;
}

.separator {
    height: 50px;
}

/*
/ MAIN CONTAINER
 */

.container {
    position: absolute;
    top: 0;
}

.dreams-container {
}

.dreams-container-fixed-width {
}

.dreams-header-container {
    background-color: var(--url-color);
    padding: var(--main-padding);
}

.dreams-content {
    background-color: #F4F4F4;
    color: black;
    padding: var(--main-padding);
    width: 100%;
    max-width: 800px;
    line-height: 1.5;
    font-family: Arial, serif;
}

.container-fixed-width {
    max-width: 1920px;
    min-width: 320px;
}

.about-container {
    padding: var(--main-padding);
    background-color: var(--url-color);
}

.about-container .star {
    width: 46px;
    height: 46px;
    margin-right: 10px;
}

.column-item {
    width: 30%;
    min-width: 280px;
    margin-right: 30px;
}

.bot-container {
    padding: var(--main-padding);
    background-color: var(--bg-color-secondary);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bot-container .bot-wrapper {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.bot-container .bot-text {
    width: 100%;
    height: 75px;
}

.bot-container .ico-container {
    height: 100px;
    display: flex;
    align-items: center;
}

.selected-container {
    padding: var(--main-padding);
    padding-top: 50px;
    background-color: var(--url-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-family: "Montserrat", "Roboto", "Arial", SansSerif, serif;
}

.selected-container .thumbnail {
    border: #FFFFFF 5px solid;
    background-color: var(--bg-color-secondary);
    width: 280px;
    height: 280px;
    margin-bottom: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.selected-container .thumbnail img {
    width: 280px;
    height: 280px;
    object-fit: cover;
}

.selected-container .thumbnail .labelBg {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    position: relative;
    bottom: 60px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}


.footer-container {
    background-image: url("../../assets/xmastree.jpg");
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-end;
}

.bottomfooter-container {
    padding: 50px 30px;
    background-color: var(--url-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bottomfooter-container a{
    color: #fff;
}

.shadowed-container{
    width: 600px;
    background-image: url("../../assets/shadow.svg");
    background-size: 600px;
}

.shadowed-text{
    padding: 50px;
    padding-bottom: 100px;
    padding-right: 80px;
}

.header-wrapper {
    width: 100%;
}

.header-container {
    padding: var(--main-padding);
}


.counter ul {
    margin-top: 10px;
    list-style-type: none;
    align-items: center;
    display: flex;
    font-size: var(--heading-text-size);
    font-weight: 700;
    color: #FFF;
}

.counter ul li {
    padding: 10px;
    border: #FFFFFF 1px solid;
    margin-right: 5px;
    margin-bottom: 5px;
}

.header-intro {
    width: 300px;
}

/*
/ MAP
 */

.map-intro {
    padding: var(--main-padding);
    background-color: #FFFFFF;
    color: black;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.map-container {
    display: flex;
    overflow: hidden;
}

.map {
    width: 50%;
    height: 600px;
}

.map-video {
    background-color: #000;
    width: 50%;
    height: 600px;
    overflow: hidden;
}

.video-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;

}

.video-text-preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-image: url("../../assets/textbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


.video-play {
    position: absolute;
    cursor: pointer;
}

.video-play .play-button{
    width: 120px;
    padding: 12px 15px;
    padding-right: 25px;
    background-color: var(--url-color);
    border-radius: 25px;
    border-width: 0;
    font-size: 14px;
    color: white;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.video-play .play-button img {
    width: 30px;
    height: 30px;
}

.map-info {
    position: absolute;
    padding-top: 15px;
    left: 15px;
}

.map-info p {
    padding: 15px 25px;
    background-color: #fff;
    border-radius: 25px;
    border-width: 0;
    font-size: var(--main-text-size);
    color: var(--url-color);
}

/*
/ Popup
 */

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
}
.popup_inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000;
    outline: none;
    line-height: 1.2;
}

.popup_inner .close_container {
    padding-top: 10px;
    padding-right: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.popup_inner .text-block {
    height: 85vh;
    padding: 50px;
    padding-top: 70px;
}

.video_outline {
    outline: none;
}

.bg-xmas {
    background-image: url("../../assets/textbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

/*
/ Buttons
 */

button, input {
    padding: 15px 25px;
    background-color: #fff;
    border-radius: 25px;
    border-width: 0;
    font-size: var(--main-text-size);
    color: var(--url-color);
    cursor: pointer;
    outline: none;
}

.button-link {
    background-color: var(--url-color);
    color: white;
}

.button-border {
    background-color: var(--url-color);
    border: #FFFFFF 1px solid;
    color: white;
}

.button-red {
    background-color: var(--url-color);
    color: white;
}

input {
    cursor: auto;
}

/*
/ Video Player
 */

.player-wrapper {
    width: 1920px;
    height: 1080px;
}

/*
 Dreams page
 */
.dreams-map {
    height: 600px;
    position: relative;
}

.collapse-map {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%);
}
/*
/ Media Queries
 */

@media (min-width: 1100px) and (max-width:1240px) {
    .bot-container h2 {
        width: 100%;
    }

    .header-intro {
        width: 100%;
    }

}

@media (min-width: 600px) and (max-width:1100px) {
    .bot-container h2 {
        width: 100%;
    }

    .header-intro {
        width: 100%;
    }

    .map {
        width: 40%;
        height: 600px;
    }

    .map-video {
        background-color: #000;
        width: 60%;
        height: 600px;
    }
}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --heading-text-size: 60px;
        --subheading-text-size: 26px;
        --main-padding: 25px;
    }

    .bot-container h2 {
        width: 100%;
    }

    .shadowed-container{
        width: 450px;
        background-image: url("../../assets/shadow.svg");
        background-size: 450px;
    }

    .column-item {
        width: 90%;
        min-width: 300px;
        margin-right: 30px;
    }

    .map-container {
        display: flex;
        flex-wrap: wrap;
    }

    .map {
        width: 100%;
        height: 600px;
    }

    .map-video {
        background-color: #000;
        width: 100%;
        height: 500px;
    }


    .bot-container .bot-wrapper {
        width: 400px;
        margin-bottom: 30px;
    }

    .bot-container .bot-text {
        width: 400px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --heading-text-size: 40px;
        --subheading-text-size: 16px;
        --main-text-size: 14px;
        --main-padding: 20px;
    }

    .bot-container h2 {
        width: 100%;
    }

    .shadowed-container{
        width: 300px;
        background-image: url("../../assets/shadow.svg");
        background-size: 300px;
    }

    .shadowed-text{
        padding: 20px;
        padding-bottom: 100px;
        padding-right: 50px;
    }

    .column-item {
        width: 100%;
        min-width: 280px;
        margin-right: 30px;
    }

    .about-container h2 {
        width: 90%;
    }

    .about-container p {
        width: 90%;
    }

    .map-container {
        display: flex;
        flex-wrap: wrap;
    }

    .map {
        width: 100%;
        height: 600px;
    }

    .map-video {
        background-color: #000;
        width: 100%;
        height: 450px;
    }

    .map-info {
        position: absolute;
        padding-top: 15px;
        left: 15px;
        right: 15px;
    }

    .bot-container .bot-wrapper {
        width: 300px;
        margin-bottom: 30px;
    }

    .bot-container .bot-text {
        width: 300px;
    }

    .selected-container {
        padding: 30px;
        padding-top: 50px;
        background-color: var(--url-color);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .selected-container h2 {
        text-align: center;
    }

    .bottomfooter-container {
        padding: 50px 30px;
        background-color: var(--url-color);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .popup_inner .text-block {
        height: 85vh;
        padding: 30px;
        padding-top: 70px;
    }

    .video-play .play-button{
        width: 100px;
        padding: 10px 15px;
        padding-right: 25px;
        background-color: var(--url-color);
        border-radius: 25px;
        border-width: 0;
        font-size: 12px;
        color: white;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
    }

    .video-play .play-button img {
        width: 20px;
        height: 20px;
    }

    .selected-container {
        padding: var(--main-padding);
        padding-top: 30px;
    }

    .selected-container .thumbnail {
        margin-right: 0;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --heading-text-size: 36px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
    }

    .video-play .play-button{
        width: 90px;
        padding: 10px 10px;
        padding-right: 25px;
        background-color: var(--url-color);
        border-radius: 25px;
        border-width: 0;
        font-size: 12px;
        color: white;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
    }

    .video-play .play-button img {
        width: 20px;
        height: 20px;
    }

    .selected-container {
        width: 100%;
    }

    .selected-container .thumbnail {
        width: 250px;
    }

    .selected-container .thumbnail img {
        width: 250px;
    }

}
